<template>
    <v-card>
        <v-img max-height="900"  aspect-ratio="1.778" :src="require('../assets/bg-phone4.png')" @click="jobApply"></v-img>
        <div class="job-block">
            <h3>招聘岗位</h3>

            <div class="job-item" v-for="(job,index) in jobList" :key="index" :title="job.name" :subtitle="job.develop" @click="jobApplyName(job)">
                <div class="job-name">
                    <div>{{job.name}}</div>
                    <div class="tips" v-if="job.region">{{job.region}}</div>
                </div>
                <div class="job-develop">{{job.develop}}</div>
            </div>
        </div>
        <!--
        <v-img max-height="900"  aspect-ratio="3.2" :src="require('../assets/bg-phone2.jpg')" @click="jobApply">
        </v-img>
        -->
    </v-card>
</template>

<script>
    export default {
        name: "phoneCollect",
        data(){
            return{
                jobList:[],
            }
        },
        mounted(){
            this.getJobList()
        },
        methods: {
            jobApply(){
                this.$router.push({path: '/resume'});
            },
            jobApplyName(job){
                this.$router.push({path: '/resume?job='+job.jobId});
            },
            getJobList(){
                this.jobLoading = true;
                let params = {}
                this.$http.post('job/jobList', params).then(({data}) => {
                    if (data.code == 0){
                        let jobList = data.jobList;
                        jobList.sort((a,b)=>{
                            return a.sortNo>b.sortNo?1 : -1
                        })
                        this.jobLoading = false;
                        this.jobList = jobList;
                    }else {
                        this.snackbar = true;
                        this.message = data.msg;
                    }
                }).catch(() => {
                    this.snackbar = true;
                    this.message = '网路请求失败！';
                })
            },
        }
    }
</script>

<style scoped>
.job-block{padding: 15px;}
.job-item{display: flex;align-items: flex-start;padding:10px 10px;box-shadow: 0 2px 6px 0 rgba(171, 171, 171, 0.45);margin-bottom: 10px;}
.job-name{flex: 1;}
.job-name .tips{font-size: 10px; color: #999;}
.job-develop{flex: 2;color: #888;}

</style>
